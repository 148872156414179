<template>
  <v-container fluid>
    <v-card elevation="5" outlined>
      <v-card-title class="primary--text card-header">
        {{ this.$t('erp.newWarehouse') }}
        <v-spacer/>

      </v-card-title>
      <v-divider class="ma-0 pa-0"/>
      <v-card-text>
        <v-form @submit="create" lazy-validation ref="form">
          <v-row
              :class="(!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm)?  'pt-12 pr-12 pl-12' : ''"
          >
            <v-col cols="12" sm="6">
              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :label="this.$t('erp.lang_storageName')"
                            :rules="[
                    v => !!v
                ]"
                            @focus="showTouchKeyboard"
                            dense
                            outlined
                            prepend-inner-icon="storage"
                            v-model="storage.storageName"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :label="this.$t('generic.lang_emailAdresse')"
                            @focus="showTouchKeyboard"
                            dense
                            outlined
                            prepend-inner-icon="email"
                            type="email"
                            v-model="storage.storageEMail"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <country :label="this.$t('generic.lang_country')"  clearable dense icon="flag" outlined v-model="storage.storageCountry"/>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="this.$t('settings.lang_city')"
                            @focus="showTouchKeyboard"
                            dense
                            outlined
                            prepend-inner-icon="location_city"
                            v-model="storage.storageCity"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :label="this.$t('generic.lang_street')"
                            @focus="showTouchKeyboard"
                            dense
                            outlined
                            prepend-inner-icon="streetview"
                            v-model="storage.storageStreet"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :label="this.$t('generic.lang_zip')"
                            @focus="showTouchKeyboard"
                            dense
                            outlined
                            prepend-inner-icon="home"
                            v-model="storage.storageZip"
              />
            </v-col>

            <v-col class="pb-0 mb-0" cols="12" sm="6">
              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :label="this.$t('generic.lang_phoneNumber')"
                            @focus="showTouchKeyboard"
                            dense
                            outlined
                            prepend-inner-icon="smartphone"
                            v-model="storage.storagePhone"
              />
            </v-col>

            <v-col class="pb-0 mb-0" cols="12" sm="6">
              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :label="this.$t('generic.lang_fax')"
                            @focus="showTouchKeyboard"
                            dense
                            outlined
                            prepend-inner-icon="local_phone"
                            v-model="storage.storageFax"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <users :label="this.$t('generic.lang_selectUser')" dense icon="person" outlined v-bind:user="storage.user_id"
                     v-model="storage.user_id"
                     :rules="[v => !!v || $t('generic.lang_required')]"/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select v-model="storage.cashierId"
                        :items="filteredCashierIDs"
                        item-text="name"
                        item-value="value"
                        :label="$t('generic.lang_cashierID')"
                        outlined
                        dense
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12">
              <v-btn :disabled="loading"
                     :loading="loading"
                     @click="create" block class="mx-auto" color="primary" large>
                <v-icon small>save</v-icon>
                {{ this.$t("generic.lang_save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
          :accept="hideTouchKeyboard"
          :cancel="hideTouchKeyboard"
          :defaultKeySet="touchKeyboard.keySet"
          :input="touchKeyboard.input"
          :layout="touchKeyboard.layout"
          :options="touchKeyboard.options"
          class="internalWidthExpanded"
          id="onScreenKeyboard"
          v-if="touchKeyboard.visible"
      />
    </div>
  </v-container>
</template>

<script>
//config
import {Events} from "@/plugins/events";
import {ENDPOINTS} from "@/config";
import mixin from "../../../../mixins/KeyboardMixIns";
import Country from "../../../common/country";
import Users from "../../../common/Users";
import { mapGetters } from 'vuex';

export default {
  components: {Users, Country},
  data: () => {
    return {
      storage: {},
      ENDPOINT: ENDPOINTS,
      loading: false,
      title: "",
      users: []
    };
  },
  mixins: [mixin],
  computed: {
    ...mapGetters({
      cashierIDs: "cashierIDs/cashiers",
    }),
    filteredCashierIDs() {
      return this.cashierIDs.map(item => ({
        name: this.$t('generic.lang_kasse') + ' ' + item.id + (item.aliasName ? ` (${item.aliasName}) ` : ''),
        value: `${item.id}`
      }))
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    create() {

      if (!this.$refs.form.validate()) return;
      this.loading = true;
      //console.log(this.storage)
      this.axios
          .post(this.ENDPOINT.ERP.STORAGEOVERVIEW.CREATE, this.storage)
          .then(result => {

            if (result.data.STATUS === "SUCCESS") {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_actionSuccessful"),
                color: "success"
              });

              this.$refs.form.reset();
            } else if (result.data.STATUS === "FAILED") {
              Events.$emit("showSnackbar", {
                message: result.data.msg,
                color: "error"
              });
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_errorOccurred"),
                color: "error"
              });
            }
          })
          .catch(err => {
            Events.$emit("showSnackbar", {
              message: err.message,
              color: "error"
            });
          }).finally(() => {
        this.loading = false;
      });
    },

    loadData() {
      this.axios.post(ENDPOINTS.SETTINGS.USER.GETALL).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.users = res.data.users;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      })
    }
  }
};
</script>
